<template>
  <div class="container">
    <Breadcrumb class="bg-transparent" :breadcrumbList="[
        { 'name': 'Admin Dashboard' }
      ]"/>
    <div class="main-container">
      <div v-if="this.flushMessage">
        <b-alert :variant="this.flushMessage.type" dismissible fade show>{{ this.flushMessage.message }}</b-alert>
      </div>
      <h1 class=" mb-2"><strong>Admin Dashboard</strong></h1>
      <div class="row mt-5 justify-content-center">
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 ">
          <Cards type="svg" image="fa-users" title="Users" :route="'/admin/users'"/>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4" v-if="this.$store.getters.hasRole('ROLE_ADMIN_DISPUTE')">
          <Cards type="svg" image="fa-gavel" title="Disputes" :route="'/admin/dispute'"/>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 ">
          <Cards type="svg" image="fa-flag" title="Reports" :route="'/admin/reports'"/>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 ">
          <Cards type="svg" image="fa-tasks" title="Tasks" :route="'/admin/tasks'"/>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 ">
          <Cards type="svg" image="fa-handshake" title="Approve tasks" :route="'/admin/tasks/approve'"/>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4" v-if="this.$store.getters.hasRole('ROLE_ADMIN_REWARD')">
          <Cards type="svg" image="fa-trophy" title="Rewards" :route="'/admin/rewards'" />
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4" v-if="this.$store.getters.hasRole('ROLE_ADMIN_FEATURED')">
          <Cards type="svg" image="fa-thumbtack" title="Featured" :route="'/admin/featured'" />
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4" v-if="this.$store.getters.hasRole('ROLE_ADMIN_VIEW_ORDERS')">
          <Cards type="svg" image="fa-shopping-cart" title="Orders" :route="'/admin/orders'" />
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4" v-if="this.$store.getters.hasRole('ROLE_ADMIN_GIFTCARDS')">
          <Cards type="svg" image="fa-gift" title="Gift Card" :route="'/admin/giftcard'" />
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4" v-if="this.$store.getters.hasRole('ROLE_ADMIN_PAYMENT')">
          <Cards type="svg" icon-type="fa-regular" image="fa-credit-card" title="Delayed Payments" :route="'/admin/payment'" />
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4" v-if="this.$store.getters.hasRole('ROLE_ADMIN_REVIEWS')">
          <Cards type="svg" image="fa-star" title="Reviews" :route="'/admin/reviews'" />
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4" v-if="this.$store.getters.hasRole('ROLE_ADMIN_FEEDBACK')">
          <Cards type="svg" image="fa-comments" title="Feedback" :route="'/admin/feedback'" />
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4" v-if="this.$store.getters.hasRole('ROLE_ADMIN_ACCOUNTING')">
          <Cards type="svg" image="fa-calculator" title="Accounting" :route="'/admin/accounting'" />
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4" v-if="this.$store.getters.hasRole('ROLE_ADMIN_JOB_BOARD')">
          <Cards type="svg" image="fa-person-chalkboard" title="Job Boards" :route="'/admin/job'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cards from '../../components/Admin/AdminCards'
import { mapActions } from 'vuex'
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'

export default {
  name: 'AdminHomePage',
  components: {
    Breadcrumb,
    Cards
  },
  props: {
    flushMessage: {
      type: Object
    }
  },
  mounted () {
    this.buildUser()
  },
  methods: {
    ...mapActions([
      'buildUser'
    ])
  }
}
</script>
